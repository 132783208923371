

import React , { lazy }from 'react';
import ReactDOM from 'react-dom/client'; // Note the change in import
import ReactGA from "react-ga4";


const App = lazy(() => import( './App'));

ReactGA.initialize('G-FC01B5K4TL');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   
    <App />
  </React.StrictMode>
);
